import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store/store/store';
import { CurrentState } from '../../store/types/CurrentState.Types';
import { FicheAutocontroleActionTypes, FicheAutoControleCritere, FicheAutoControleGroup, FicheAutocontrole, FicheAutocontroleTab, TooltipCritere } from '../../store/types/FicheAutocontrole.Types';
import { LabelCritereAutocontroleStyled, SwitchAutocontroleStyled } from '../../styles/CritereAutocontrole.style';
import StateService from '../../utils/state.service';
import Critere from '../Criteres/CritereAutocontole';
import SwitchIconDefault from "./../../assets/value-indicator-default.svg";
import infoIcon from "./../../assets/info.svg";
import { Alert } from '@material-ui/lab';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Helper from '../Helper/Helper';
import { HelperTypeEnum } from '../Helper/HelperTypeEnum';
import ImageSwitch from '../../assets/switch.png';

function getModalStyle() {
   return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 4, 3),
    },
  }));


interface PropsFromState {
    group:FicheAutoControleGroup
}

interface PropsFromDispatch {
    data: FicheAutocontrole,
    updateTab:Function,
    currentState:CurrentState
}

type AllProps = PropsFromState & PropsFromDispatch;

const GroupAutocontrole: React.FC<AllProps> = ({group, data, updateTab, currentState}) => {

    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const handleOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateTab(StateService.changeGroupState(data.tabs[currentState.currentPage -1], currentState.currentGroup, event.target.checked), currentState.currentPage -1)
    };

    const isCriteriaConditionneShowed = (critere:FicheAutoControleCritere, criteriaConditionne:FicheAutoControleCritere) => {
        const indexChoiceFromValue = (critere.choices as string[]).findIndex(choice => choice === critere.value)
        if(indexChoiceFromValue !== -1){
            let tabCriteresConditionnesSelected = (critere.criteresConditionnes as FicheAutoControleCritere[][])[indexChoiceFromValue]
            return tabCriteresConditionnesSelected.some(critereSelected => critereSelected.id === criteriaConditionne.id)
        }
        return false
    }

    const displayConditionalCriterias = (critere:FicheAutoControleCritere) => {
        let criteriasToDisplay: JSX.Element[] = [];
        if(critere.criteresConditionnes && critere.criteresConditionnes.length){
            critere.criteresConditionnes.forEach(critCondionnees => {
                critCondionnees.forEach(critCond => {
                    if(isCriteriaConditionneShowed(critere,critCond)){
                        criteriasToDisplay.push(<Critere critere={critCond} key={'GroupAutocontrole-'.concat(group.title).concat('-Critere-').concat(critCond.title)}/>);
                    }
                })
            })
        }
        return criteriasToDisplay
    }

    const displayHelper = (tooltip:TooltipCritere) => {
        if(tooltip){
            return (
                <Helper 
                type={tooltip.type ? (tooltip.type.toLowerCase() as HelperTypeEnum ) : HelperTypeEnum.INFO}
                title={tooltip.title}
                description={tooltip.description} />
            )
        }
        
    }

    const createCritere = () => {
        return group.criterias.map((critere:FicheAutoControleCritere) =>
            <> 
                {displayHelper(critere.tooltip as TooltipCritere)}
                <Critere critere={critere} key={'GroupAutocontrole-'.concat(group.title).concat('-Critere-').concat(critere.title)}/>
                {displayConditionalCriterias(critere)}
            </>
        )
    }

    const getGroupActivatedLabel = () => {
        return (<span>Le groupe est {group.activated ? 'activé' : 'désactivé'}</span>);
    }

    const getGroupsCritarias= () => {
        return <>
                {group.active ?
                    <>
                    <div className="critera-label">
                        <LabelCritereAutocontroleStyled> 
                        <img src={infoIcon} alt="" className="icon-info"  onClick={() => handleOpen()}/>
                        {getGroupActivatedLabel()}
                        <SwitchAutocontroleStyled className="elt-float-right" checked={group.activated} onChange={handleChangeSwitch} icon={<img src={SwitchIconDefault} alt=""/>}/> 
                        </LabelCritereAutocontroleStyled>
                    </div>
                    </>
                : ""
            }
            {createCritere()}
        </>
    }

    return(
        <div className="group-autocontrole">

             <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                    <Alert severity='info' style={modalStyle} className={classes.paper + " info-alerte alert-text"}>
                        Vous pouvez désactiver la totalité des critères du groupe <span className="alerte-info-title">{currentState.currentPage > 0 && currentState.currentGroup  > -1 ? data.tabs[currentState.currentPage -1].groups[currentState.currentGroup].title : "" }</span> s'ils ne concernent pas ce chantier.
                        <br />
                        En cliquant sur le bouton suivant : 
                        <div className='centerImageInformation'>
                            <img src={ImageSwitch} alt="" />
                        </div>
                        Cette désactivation est modifiable jusqu'à la validation de l'autocontrôle.<br />                        
                        Vous pouvez également désactiver des critères dans chacun des groupes.
                        <div>
                            <Button className="btn-close-modal" onClick={() =>  handleClose()}>Fermer</Button>
                        </div>
                    </Alert>  
             </Modal>

            {getGroupsCritarias()}


        </div>
    )
}

 
const mapStateToProps = ({ ficheAutocontrole, currentState }: ApplicationState) => ({
    data: ficheAutocontrole.data, 
    currentState: currentState
  });
  
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
return {
    updateTab:(data: FicheAutocontroleTab, indexPage:number) => dispatch({type: FicheAutocontroleActionTypes.UPDATE_TAB, payload: data, currentPage: indexPage})
}
};
  
export default connect(mapStateToProps, mapDispatchToProps)(GroupAutocontrole);