import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@material-ui/core";
import { FicheAutocontrole, FicheAutocontroleActionTypes, ParamsSynthese, ParamsSyntheseInfosGenerales } from '../../store/types/FicheAutocontrole.Types';
import { ApplicationState } from '../../store/store/store';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button } from '@material-ui/core';
import DateUtils from '../../utils/DateUtils';
import ProgressBarService from '../../utils/progressBar.service';
import { TransitionProps } from "@material-ui/core/transitions";
import { useHistory } from 'react-router-dom';
import { AUTOCONTROLE_STATUT, GeneralInformationsState } from '../../store/types/generalInformations.Types';
import { API } from '../../utils/callAPI';
import { REDIRECT_HOME_ACQ, REDIRECT_MAIL, SEVERITY } from '../../utils/constantes/contantes';
import SyntheseTab from '../Synthese/SyntheseTabs/SyntheseTab';
import SyntheseInfosGenerales from '../Synthese/SyntheseInfosGenerales/SyntheseInfosGenerales'
import { TabSynthese, TabSyntheseStateActions, tabTabSynthese } from '../../store/types/TabSynthese.Types';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { getUserRole } from '../../utils/AccessToken';
import { userRoles } from '../../store/types/user.Types';
import ProgressBar from '../ProgressBar/ProgressBar';
import SynthesePhotos from '../Synthese/SynthesePhotos/SynthesePhotos';
import SyntheseCommentaires from '../Synthese/SyntheseCommentaires/SyntheseCommentaires';
import { DownloadedPictureTypes, OldPictureTypes, PictureDownloadedState, PictureState, PictureTypes } from '../../store/types/pictures.Types';
import { uploadToS3 } from '../../utils/upload.image.s3.service';
import { GoBackCurrentStateActions } from '../../store/types/GoBackState.Types';
import IntervenantUtils from '../../utils/IntervenantUtils';
import { MessagesAlertState, MessagesAlertTypes } from '../../store/types/MessagesAlert.Types';
import { ReInitPicCompActions } from '../../store/types/ReInitPicComp.Types';
import SyntheseValidationDirectionQualite from '../Synthese/SyntheseValidationDirectionQualite/SyntheseValidationDirectionQualite';
import { CurrentStateActions } from '../../store/types/CurrentState.Types';

interface PropsFromDispatch {
    data: FicheAutocontrole,
    tabSynthese: TabSynthese,
    updateTabSyntheseState: Function,
    isSaved: boolean,
    isDateSaved: boolean,
    isIntervenantsSaved: boolean,
    setIsSaved: Function,
    setIsDateSaved: Function,
    setIsIntervenantsSaved: Function,
    pictures: PictureState[],
    initGoBackState: Function,
    fetchData: Function,
    oldPictures: Array<string>,
    deleteOldPicture: Function,
    updateMessageSnackBar: Function,
    deletePicsFromStore: Function,
    generalInformations: GeneralInformationsState,
    downloadedPictures: PictureDownloadedState[],
    deleteDownloadedPicsFromStore: Function,
    reInitPicComp: Function,
    updateCurrentPage:Function,
    updateCurrentGroup:Function,
    updateIsTabInfos:Function
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const TabSyntheseAutocontrole: React.FC<PropsFromDispatch> = ({ deleteDownloadedPicsFromStore, downloadedPictures, data, pictures, tabSynthese, updateTabSyntheseState, isSaved, isDateSaved, setIsSaved, setIsDateSaved, setIsIntervenantsSaved, isIntervenantsSaved, initGoBackState, fetchData, oldPictures, deleteOldPicture, updateMessageSnackBar, deletePicsFromStore, reInitPicComp, updateCurrentPage, updateCurrentGroup, updateIsTabInfos }) => {

    /**
    * Constantes et state
    */
    const history = useHistory();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [, setOpenAlertDate] = React.useState(false);
    const [, setOpenAlertIntervenants] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [closeAutocontrole, setCloseAutocontrole] = React.useState(false);
    const [, setAlertOpenIntervenants] = React.useState(false);
    const [validAutocontrolCMQ, setValidAutocontrolCMQ] = React.useState(false);
    const [fermerAutocontrolCMQ, setFermerAutocontrolCMQ] = React.useState(false);

    useEffect(() => {
        console.info("Suppression des images downloaded du store...")
        deleteDownloadedPicsFromStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const messageUpdateSnackbar = () => {
        return !closeAutocontrole ? ["Votre autocontrôle a bien été validé !"] : ["La fiche autocontrôle a été fermée avec succès ! \nL'équipe chantier a été informée automatiquement par mail."]
    }

    const updateStatut = () => {
        setOpenDialog(false)
        setLoading(true)
        let request = {
            id_pk: data.id_pk as string,
            id_sk: data.id_sk as string,
            statut: displayCloseBtn() ? AUTOCONTROLE_STATUT.FERME : AUTOCONTROLE_STATUT.FICHE_COMPLETEE
        }
        data.tabInfos.dateValidation = new Date().getTime();

        API.updateAutocontrole(request, ProgressBarService.getComplitionPercent(data), request.statut)
            .then((response: any) => {
                const severity: string = response ?.data ?.severity;
                tabSynthese.statut = AUTOCONTROLE_STATUT.FICHE_COMPLETEE;
                updateTabSyntheseState(tabSynthese)
                setLoading(false);
                if (!severity) {
                    updateMessageSnackBar({ messages: messageUpdateSnackbar(), severity: SEVERITY.SUCCESS })
                }
                history.push(REDIRECT_HOME_ACQ)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    /**
    * Metier :
    */
    const isAutocontroleComplete = () => {
        if (ProgressBarService.getComplitionPercent(data) === 100
            && !DateUtils.isBeforeNow(data.tabInfos.dateLimite)
            && tabSynthese.statut === AUTOCONTROLE_STATUT.OUVERT) {
            return true
        } else {
            return false
        }
    }

    const canSendReport = () => {
        return (tabSynthese.statut === AUTOCONTROLE_STATUT.FICHE_COMPLETEE || tabSynthese.statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE)
    }

    const goToTabInfos = () => {
        window.scroll(0,0)
        updateCurrentPage(0);
        updateCurrentGroup(-1);
        updateIsTabInfos(true)
    }

    const handleSendReport = () => {
        if (!isSaved) {
            saveInfosFromSynthese()
        }
        goToTabInfos();
        history.push(REDIRECT_MAIL)
    }

    const setAfterUpdate = (severity: string) => {
        if (oldPictures.length > 0) {
            oldPictures.forEach(oldPic => {
                API.deletePicFromS3(oldPic).then(() => {
                    console.log("Image supprimée : " + oldPic);
                    deleteOldPicture(oldPic);
                }, err => {
                    console.error(err);
                });
            });
        }
        updateTabSyntheseState(tabSynthese)
        setLoading(false)
        if (!isDateSaved) {
            setOpenAlertDate(true && !severity)
            updateMessageSnackBar({ messages: ["La date limite de saisie a bien été enregistrée."], severity: SEVERITY.SUCCESS })
        }
        if (!isIntervenantsSaved && !severity) {
            setOpenAlertIntervenants(true)
            updateMessageSnackBar({ messages: ["La modification des intervenants a bien été enregistrée."], severity: SEVERITY.SUCCESS })
        }
        setIsIntervenantsSaved(true)
        setIsDateSaved(true)
        setIsSaved(true)
        initGoBackState();
    }

    const dateToCheck = () => {
        return (data.statut as string === AUTOCONTROLE_STATUT.OUVERT || data.statut as string === AUTOCONTROLE_STATUT.FICHE_COMPLETEE)
    }

    const saveInfosFromSynthese = () => {
        setLoading(true)

        const paramsToCheck = {
            chargeMissionQualite: tabSynthese.infosGenerales.CMQ,
            responsableProgramme: tabSynthese.infosGenerales.responsableProgramme,
            responsableTechnique: tabSynthese.infosGenerales.responsableTechnique,
            conducteurTravaux: tabSynthese.infosGenerales.conducteurTravaux,
            date: tabSynthese.infosGenerales.dateLimite
        }

        // ne pas verifier la date
        if (!IntervenantUtils.isParametresIntervenantsOk(paramsToCheck, dateToCheck())) {
            let message: string[] = IntervenantUtils.getMessagesParametresIntervenants(paramsToCheck, dateToCheck())
            setAlertOpenIntervenants(true)
            setLoading(false);
            updateMessageSnackBar({ messages: message, severity: SEVERITY.ERROR })
            return
        }

        const tabInfosFromTabSynthese: ParamsSyntheseInfosGenerales = {
            id_pk: tabSynthese.id_pk,
            id_sk: tabSynthese.id_sk,
            responsableProgramme: tabSynthese.infosGenerales.responsableProgramme,
            responsableTechnique: tabSynthese.infosGenerales.responsableTechnique,
            CMQ: tabSynthese.infosGenerales.CMQ,
            conducteurTravaux: tabSynthese.infosGenerales.conducteurTravaux,
            directeurProgrammes: tabSynthese.infosGenerales.directeurProgrammes,
            directeurOperationnel: tabSynthese.infosGenerales.directeurOperationnel,
            dateLimite: tabSynthese.infosGenerales.dateLimite,
            dateLivraisionGPI: tabSynthese.infosGenerales.dateLivraisionGPI,
            dateValidation: tabSynthese.infosGenerales.dateValidation
        }

        const paramSynthese: ParamsSynthese = {
            id_pk: tabSynthese.id_pk,
            id_sk: tabSynthese.id_sk,
            infosGeneralesSynthese: tabInfosFromTabSynthese,
            photos: tabSynthese.photos,
            commentaires: tabSynthese.commentaires,
            commentaireCmq: tabSynthese.commentaireCmq,
            validationDirectionQualite: tabSynthese.validationDirectionQualite
        }

        API.updateAutocontrole(paramSynthese as ParamsSynthese, ProgressBarService.getComplitionPercent(data), data.statut)
            .then((response: any) => {
                console.log(response)
                const severity: string = response ?.data ?.severity;
                if (pictures.length > 0) {
                    let promises: any[] = [];
                    pictures.forEach(img => {
                        promises.push(uploadToS3(img.name, img.type, img.pic));
                    });
                    Promise.all(promises)
                        .then(
                            () => {
                                console.log("Images sauvegardées !");
                                deletePicsFromStore();
                                setAfterUpdate(severity);
                                reInitPicComp();
                            }
                        ).catch(error => {
                            console.error(error);
                            setLoading(false)
                        });

                } else {
                    setAfterUpdate(severity)
                }
                console.log("Update des infos de la synthèse ok !")
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                initGoBackState();
            })

    }


    /**
    * Display :
    */

    const buildTabsForSynthese = (tabs: tabTabSynthese[]) => {
        if (!tabs) return;
        return tabs.map((tab: tabTabSynthese) =>
            <SyntheseTab key={tab.id.toString()} tab={tab} isClickable={!(tabSynthese.statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE || tabSynthese.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE || tabSynthese.statut === AUTOCONTROLE_STATUT.RAPPORT_ENVOYE)} />
        );
    }

    const canBeClose = () => {
        return tabSynthese.statut !== AUTOCONTROLE_STATUT.FERME;
    }

    const displayCloseBtn = () => {
        if (closeAutocontrole) {
            return tabSynthese.statut === AUTOCONTROLE_STATUT.OUVERT || tabSynthese.statut === AUTOCONTROLE_STATUT.FICHE_FINALISEE
                || tabSynthese.statut === AUTOCONTROLE_STATUT.FICHE_COMPLETEE || tabSynthese.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE;
        } else {
            return false;
        }

    }

    const handleSwitchAutocontrole = () => {
        setCMDFermerAutocontrolOpenDialog();
    }

    const getDialogText = () => {
        if (!!data) {
            if (tabSynthese.statut !== AUTOCONTROLE_STATUT.FERME) {
                if (getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) {
                    if (validAutocontrolCMQ) {
                        return <span>Voulez-vous finaliser votre saisie ? <br /> Une fois que la date limite de saisie sera dépassée, le chargé de mission qualité sera informé automatiquement par mail.</span>
                    }
                    if (fermerAutocontrolCMQ) {
                        return <span>Voulez-vous fermer l'accès à la fiche autocontrôle à l'équipe chantier ? <br /> Cette action entraine la suppression définitive de l’autocontrôle.</span>
                    }

                } else {
                    return <span>Voulez-vous finaliser votre saisie ? <br /> Une fois que la date limite de saisie sera dépassée, le chargé de mission qualité sera informé automatiquement par mail.</span>
                }
            } else {
                return <span>Voulez-vous finaliser votre saisie ? <br /> Le chargé de mission qualité sera informé automatiquement par mail.</span>
            }
        }
    }

    const setCMDValidAutocontrolOpenDialog = () => {
        setValidAutocontrolCMQ(true);
        setFermerAutocontrolCMQ(false);
        setOpenDialog(true);
    }

    const setCMDFermerAutocontrolOpenDialog = () => {
        setValidAutocontrolCMQ(false);
        setFermerAutocontrolCMQ(true);
        setOpenDialog(true);
    }

    return (
        <div >
            {(!loading && !!tabSynthese.infosGenerales) ?
                <div>
                    <SyntheseInfosGenerales />
                    {buildTabsForSynthese(tabSynthese.tabs)}

                    {(getUserRole().toLowerCase() === userRoles.cmq.toLowerCase()) ?
                        <>
                            <SynthesePhotos />
                            <SyntheseCommentaires />
                            <SyntheseValidationDirectionQualite />
                            <div className="synthese-avancement">
                                <div className="synthese-title">
                                    Avancement global de l'autocontrole : {tabSynthese ?.infosGenerales ?.advancement === -1 ? 0 : tabSynthese ?.infosGenerales ?.advancement}%
                                </div>
                                <div className="synthese-progress-bar">
                                    <ProgressBar typeAutocontrole={tabSynthese ?.infosGenerales ?.typeAutocontrole} value={tabSynthese ?.infosGenerales ?.advancement} />
                                </div>
                            </div>
                            {(tabSynthese.statut !== AUTOCONTROLE_STATUT.DELAI_DEPASSE && tabSynthese.statut !== AUTOCONTROLE_STATUT.FICHE_FINALISEE && tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE) ?
                                <div className='btn-validate-synthese-container'
                                    title={!isAutocontroleComplete() ? "POUR VALIDER VOTRE SAISIE, VEUILLEZ RENSEIGNER L'ENSEMBLE DES CRITERES SUR LA FICHE." : ""}>
                                    <Button className="btn-validate-synthese" key="btn-autocontrole-validate" variant="outlined"
                                        onClick={() => setCMDValidAutocontrolOpenDialog()} disabled={!isAutocontroleComplete()}>
                                        Valider l'autocontrole
                                    </Button>
                                </div>
                                : ''}
                            {tabSynthese.statut !== AUTOCONTROLE_STATUT.RAPPORT_ENVOYE ?
                                <div className='btn-validate-synthese-container'>
                                    <Button className="btn-validate-synthese" key="btn-autocontrole-validation" variant="outlined"
                                        onClick={saveInfosFromSynthese} disabled={isSaved}>
                                        Enregistrer
                                    </Button>

                                    <Button className="btn-validate-synthese " key="btn-autocontrole-close" variant="outlined"
                                        onClick={() => { setCloseAutocontrole(true); handleSwitchAutocontrole() }} disabled={!canBeClose()}>
                                        Fermer l'autocontrôle
                                    </Button>
                                    {tabSynthese.statut === AUTOCONTROLE_STATUT.DELAI_DEPASSE ?
                                        <Button className="btn-validate-synthese" key="btn-autocontrole-depasse" variant="outlined"
                                            onClick={handleSendReport}>
                                            Envoyer le mail d'alerte
                                        </Button> :
                                        <Button className="btn-validate-synthese" key="btn-autocontrole-envoi" variant="outlined"
                                        onClick={handleSendReport} disabled={!canSendReport() || (tabSynthese.photos.length !== downloadedPictures.length)}> {/* en dur pour mes tests AO */}
                                        Envoyer le rapport
                                        </Button>
                                    }


                                </div>
                                : ''}

                        </> : <>
                            {(tabSynthese.statut !== AUTOCONTROLE_STATUT.DELAI_DEPASSE && tabSynthese.statut !== AUTOCONTROLE_STATUT.FICHE_FINALISEE) ?
                                <div className='btn-validate-synthese-container'
                                    title={!isAutocontroleComplete() ? "POUR VALIDER VOTRE SAISIE, VEUILLEZ RENSEIGNER L'ENSEMBLE DES CRITERES SUR LA FICHE." : ""}>
                                    <Button className="btn-validate-synthese" key="btn-autocontrole-validate" variant="outlined"
                                        onClick={() => setOpenDialog(true)} disabled={!isAutocontroleComplete()}>
                                        Valider l'autocontrole
                                    </Button>
                                </div>
                                : ''}
                        </>
                    }
                    <Dialog
                        open={openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className="boldTitle">{"Attention"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {getDialogText()}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => updateStatut()} color="primary" className='buttonDialog'>
                                OUI
                            </Button>
                            <Button onClick={() => {setValidAutocontrolCMQ(false); setFermerAutocontrolCMQ(false);setCloseAutocontrole(false);setOpenDialog(false);} } color="primary" className='buttonDialog'>
                                NON
                            </Button>
                        </DialogActions>
                    </Dialog>        
        
                </div>
                : <></>}
            <LoadingBackdrop loading={loading} />
        </div>
    );

}

const mapStateToProps = ({ ficheAutocontrole, pictures, tabSyntheseState, oldPictures, generalInformations, downloadedPictures }: ApplicationState) => ({
    data: ficheAutocontrole.data,
    tabSynthese: tabSyntheseState.data,
    isSaved: tabSyntheseState.isSaved,
    isDateSaved: tabSyntheseState.isDateSaved,
    isIntervenantsSaved: tabSyntheseState.isIntervenantsSaved,
    pictures: pictures,
    oldPictures: oldPictures,
    generalInformations: generalInformations,
    downloadedPictures: downloadedPictures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setIsSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISSAVED, payload: params }),
        setIsDateSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISDATESAVED, payload: params }),
        setIsIntervenantsSaved: (params: boolean) => dispatch({ type: TabSyntheseStateActions.SET_ISINTERVENANTSSAVED, payload: params }),
        updateTabSyntheseState: (data: TabSynthese) => dispatch({ type: TabSyntheseStateActions.UPDATE_STATE, payload: data }),
        initGoBackState: () => dispatch({ type: GoBackCurrentStateActions.INIT_STATE }),
        fetchData: (data: FicheAutocontrole) => dispatch({ type: FicheAutocontroleActionTypes.FETCH_DATA, payload: data }),
        deleteOldPicture: (name: string) => dispatch({ type: OldPictureTypes.DELETE_OLD_PIC, picName: name }),
        updateMessageSnackBar: (params: MessagesAlertState) => dispatch({ type: MessagesAlertTypes.UPDATE_STATE, payload: params }),
        deletePicsFromStore: () => dispatch({ type: PictureTypes.DELETE_ALL_PIC }),
        deleteDownloadedPicsFromStore: () => dispatch({ type: DownloadedPictureTypes.DELETE_ALL_PIC }),
        reInitPicComp: () => dispatch({ type: ReInitPicCompActions.RE_INIT }),
        updateCurrentPage: (params: number) => dispatch({type: CurrentStateActions.CHANGE_PAGE, params: params}),
        updateCurrentGroup: (params: number) => dispatch({type: CurrentStateActions.CHANGE_GROUP, params: params}),
        updateIsTabInfos: (params: boolean) => dispatch({type: CurrentStateActions.IS_TABINFOS, params: params})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TabSyntheseAutocontrole);
